<template>
  <div class="reset">
    
    <!-- <div style="margin: 16px">
      <van-button
        type="info"
        style="width: 100%"
        @click="goWxAuth"
        >绑定微信</van-button
      >
    </div> -->
  </div>
</template>

<script>
import { Notify, Button, Field, Form } from "vant";

// import { getCode } from "@/utils/common";
export default {
  name: "resetPassword",
  components: {
    [Notify.name]: Notify,
    [Button.name]: Button,
    [Field.name]: Field,
    [Form.name]: Form,
  },

  data() {
    return {
      isLoading: false,
    };
  },
  mounted() {
      this.goWxAuth();
  },
  created() {},
  methods: {
    goWxAuth() {
      let url = process.env.VUE_APP_WX_REDIRECTURL + "?p=xuexi";
      window.location.href =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxd0ed98b5f2e590fa&redirect_uri=" +
        url +
        "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
    },
  },
};
</script>
<style lang="scss" scoped>
.reset {
  height: 100vh;
  background: #eef1f6;
}
.tips {
  padding: 16px;
  color: #333;
  font-size: 14px;
}
</style>